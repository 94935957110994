// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Não consegue encontrar um número de lote?",
  GetBatchNumber: "Obter número de lote",
  InputBatchNumber: "Inserir número de lote",
  ProductTracking: "Rastreamento do produto",
  IngredientsTracking: "Rastreamento de ingredientes",
  IngredientsStory: "História dos ingredientes",
  ProductDetails: "Detalhes do produto",
  SeeMyJourney: "Ver minha jornada",
  WhatPeopleSaidAboutUs: "O que as pessoas disseram sobre nós",
  FeaturedIn: "Destaque em",
  YouMightAlsoLike: "Você também pode gostar",
  ViewCertificate: "Ver certificado",
  MoreInformation: "Mais informações",
  Document: "Documento",
  PoweredBy: "Desenvolvido por",
  Explore: "Explorar",
  FollowUs: "Siga-nos",
  // New Landing page / Template 5
  Details: "Detalhes",
  Passport: "Passaporte",
  Certificates: "Certificados",
  Socials: "Redes sociais",
  Home: "Início",
  AboutUs: "Sobre nós",
  TermsAndConditions: "Termos e condições",
  PrivacyPolicy: "Política de privacidade",
  Reviews: "Avaliações",
  NutritionalInformation: "Informação nutricional",
  Videos: "Vídeos",
  SocialMedia: "Mídias sociais",
  Attributes: "Atributos",
  MoreReviews: "Mais avaliações",
  AvailableAt: "Disponível em",
  Nutrition: "Nutrição",
  Competition: "Competição",
  Loyalty: "Lealdade",
  Promotions: "Promoções",
  ThankYouForYourSubmission:
    "Obrigado por sua inscrição. Seu recibo será verificado e a confirmação de sua participação será enviada por e-mail.",
  Submit: "Enviar",
  UploadYourReceipt: "Carregar seu recibo",
  SupplyChainStory: "História da cadeia de suprimentos",
  View: "Ver",
  ViewProducts: "Ver produtos",
  ViewMap: "Ver mapa",
  ViewMore: "Ver mais",
  ViewLess: "Ver menos",
  SeeThisProductsJourney: "Veja a jornada deste produto",
  ProductJourney: "Jornada do produto",
  InteractiveMap: "Mapa interativo",
  ViewSupplyChain: "Ver cadeia de suprimentos",
  BatchSpecificCertificates: "Certificados específicos do lote",
  CompleteChain: "Cadeia completa",
  BatchLookupFailedTryAgainLater: "Falha na pesquisa de lote, tente novamente mais tarde.",
}
