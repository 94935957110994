import { EngagementDetailsFragment, SkuDetailsFragment } from "@/graphql/hooks/fragments.generated"

export const isVideoFiletype = (filename: string) => {
  const tsName = String(filename) // Being typesafe because I've encountered some strings without the full String class attached.
  if (tsName.indexOf(".mp4") >= 0) return true
  if (tsName.indexOf(".mkv") >= 0) return true
  if (tsName.indexOf(".avi") >= 0) return true
  return false
}

export function parseJson(str: string) {
  try {
    return JSON.parse(str)
  } catch (e) {
    return str
  }
}

export function isTimestampBeforeNow(timestampString: string): boolean {
  const timestampDate = new Date(timestampString)
  const currentDate = new Date()

  return timestampDate < currentDate
}

/** Finds the best promotion attached to a sku to serve on a landing page. */
export function selectBestPromotion(sku: {
  promotions?: SkuDetailsFragment["promotions"]
}): EngagementDetailsFragment | undefined {
  if (!sku.promotions || sku.promotions.length === 0) return // There is no promotion on the sku

  // TODO: Select the best promotion from the list to return. (currently this just returns the first valid one in the list)

  for (let i = 0; i < sku.promotions.length; i++) {
    const p = sku.promotions[0]

    // Skip if this promo shouldn't be shown to consumers (note: the backend shouldn't return it)
    if (p.endDate && p.endDate !== "" && isTimestampBeforeNow(p.endDate)) continue
    if (p.isPublished === false) continue
    if (p.isApproved === false) continue

    return p
  }

  return // There is no promotion on the sku
}

type KnownSkuAsset = "primaryColor" | "secondaryColor"

interface GetAssetFn {
  (sku: { assets: SkuDetailsFragment["assets"] } | undefined | null, key: string): string | undefined
  (sku: { assets: SkuDetailsFragment["assets"] } | undefined | null, key: KnownSkuAsset): string | undefined
}

/**
 * Resolves an asset's value (such as a sku's primaryColor) if the sku has been saved with one.
 *
 * Usage: `getAssetValue(sku, "primaryColor")`
 * @param key the name/title used to identify the asset, such as "primaryColor".
 * @returns Asset are always stored in a string format but sku's might not always have a value defined for every asset.
 * It is also best practice to handle the edge case that an assets value may have been set to an empty string rather than undefined when being cleared.
 */
export const getAssetValue: GetAssetFn = (sku, key) => sku?.assets.find((x) => x.title === key)?.value
// Same as:
// if (!sku) return
// const asset = sku.assets.find((x) => x.title === key)
// return asset?.value
