// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "找不到批号？",
  GetBatchNumber: "获取批号",
  InputBatchNumber: "输入批号",
  ProductTracking: "产品追踪",
  IngredientsTracking: "成分追踪",
  IngredientsStory: "成分故事",
  ProductDetails: "产品详情",
  SeeMyJourney: "查看我的旅程",
  WhatPeopleSaidAboutUs: "人们对我们说了什么",
  FeaturedIn: "推荐",
  YouMightAlsoLike: "您可能也喜欢",
  ViewCertificate: "查看证书",
  MoreInformation: "更多信息",
  Document: "文件",
  PoweredBy: "供电",
  Explore: "探索",
  FollowUs: "关注我们",
  // New Landing page / Template 5
  Details: "详情",
  Passport: "护照",
  Certificates: "证书",
  Socials: "社交",
  Home: "首页",
  AboutUs: "关于我们",
  TermsAndConditions: "条款和条件",
  PrivacyPolicy: "隐私政策",
  Reviews: "评论",
  NutritionalInformation: "营养信息",
  Videos: "视频",
  SocialMedia: "社交媒体",
  Attributes: "属性",
  MoreReviews: "更多评论",
  AvailableAt: "在哪里可以买到",
  Nutrition: "营养",
  Competition: "竞争",
  Loyalty: "忠诚度",
  Promotions: "促销",
  ThankYouForYourSubmission: "感谢您的提交。您的收据将被验证，参与确认将通过电子邮件发送给您。",
  Submit: "提交",
  UploadYourReceipt: "上传您的收据",
  SupplyChainStory: "供应链故事",
  View: "查看",
  ViewProducts: "查看产品",
  ViewMap: "查看地图",
  ViewMore: "查看更多",
  ViewLess: "查看更少",
  SeeThisProductsJourney: "查看此产品的旅程",
  ProductJourney: "产品旅程",
  InteractiveMap: "交互地图",
  ViewSupplyChain: "查看供应链",
  BatchSpecificCertificates: "批次特定证书",
  CompleteChain: "完整链",
  BatchLookupFailedTryAgainLater: "批次查找失败，请稍后再试。",
}
