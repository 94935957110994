import React from "react"

import { useParams, useSearchParams } from "react-router-dom"

import ErrorDisplayComponent from "@/components/ErrorDisplay"
import SimpleLoadingPlaceholder from "@/components/SimpleLoadingPlaceholder"
import LandingPageData from "@/controllers/LandingPageData"
import { useLandingPageDataQuery, useUidByBatchQuery } from "@/graphql/hooks/queries.generated"
import useStyles from "@/helpers/hooks/useStyles"
import Switcher from "@/landingPages/Switcher"

export type ByUidOrBatchProps = {
  uid?: string
  batch?: string
}

export const ByUidOrBatch = (props: ByUidOrBatchProps) => {
  const styles = useStyles({
    error: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      maxWidth: "400px",
      margin: "0px auto",
    },
  })

  //#region Get info from url or props
  const { uid: uidFromProps, batch: batchFromProps } = props
  const { batch: batchFromRouter, uid: uidFromRouter } = useParams()
  const [searchParams] = useSearchParams()
  const batchFromSearch = searchParams.get("batch")
  const uidFromSearch = searchParams.get("productUID")
  const batchCode = batchFromProps ?? batchFromRouter ?? batchFromSearch
  const [productUID, setProductUID] = React.useState(uidFromProps ?? uidFromRouter ?? uidFromSearch)
  //#endregion

  //#region Warnings to help debug potential weird behaviours
  if (batchFromRouter && batchFromSearch) {
    console.warn(
      `Ignorming batch code '${batchFromSearch}' from search and using '${batchFromRouter}' from router stack.`,
    )
  }
  if ((uidFromRouter || uidFromSearch) && batchCode) {
    console.warn(
      `Product uid will be loaded from batch code '${batchCode}', which will override the uid given in the url.`,
    )
  } else if (uidFromRouter && uidFromSearch) {
    console.warn(`Ignorming uid '${uidFromSearch}' from search and using '${uidFromRouter}' from router stack.`)
  }
  //#endregion

  const resolveBatch = useUidByBatchQuery({
    variables: { batchCode: batchCode ?? "" },
    skip: !batchCode,
  })

  React.useEffect(() => {
    if (resolveBatch.data && resolveBatch.data.tags.length > 0) {
      setProductUID(resolveBatch.data.tags[0].uid)
    }
  }, [resolveBatch.data])

  const { data, loading, error } = useLandingPageDataQuery({
    variables: { uid: productUID ?? "" },
    skip: !productUID,
  })

  // Loading
  if (loading || resolveBatch.loading) {
    return <SimpleLoadingPlaceholder />
  }

  // Error
  if (resolveBatch.error) {
    console.error("error while lving batch code into uid", resolveBatch.error)
    return <ErrorDisplayComponent className={styles.error} errorMessage={resolveBatch.error.message} />
  }
  if (error) {
    console.error("error while loading product data", error)
    return <ErrorDisplayComponent className={styles.error} errorMessage={error.message} />
  }
  if (!data) {
    if (resolveBatch.data?.tags && resolveBatch.data.tags.length === 0)
      return <ErrorDisplayComponent className={styles.error} errorMessage='Batch not found.' />
    else return <ErrorDisplayComponent className={styles.error} errorMessage='An unknown error occurred.' />
  }

  // Product with no sku
  if (!data.product.sku) {
    return <h1>Invalid Product.</h1>
  }

  const isBatchScan = batchCode != null
  const canCompleteChain = !isBatchScan && !!data.product.sku.canCompleteChain

  return (
    <LandingPageData.Provider
      initialState={{
        sku: data.product.sku,
        itemUID: data.product.uid,
        lotCode: batchCode ?? undefined,
        enableChainCompletion: canCompleteChain,
      }}
    >
      <Switcher
        sku={data.product.sku}
        product={isBatchScan ? undefined : data.product}
        enableChainCompletion={canCompleteChain}
        batchCodePrefill={batchCode ?? undefined}
      />
    </LandingPageData.Provider>
  )
}

export default ByUidOrBatch
