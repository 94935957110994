// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Parti numarası bulunamıyor",
  GetBatchNumber: "Parti numarasını al",
  InputBatchNumber: "Parti numarasını girin",
  ProductTracking: "Ürün takibi",
  IngredientsTracking: "İçerik takibi",
  IngredientsStory: "İçerik hikayesi",
  ProductDetails: "Ürün detayları",
  SeeMyJourney: "Yolculuğumu gör",
  WhatPeopleSaidAboutUs: "İnsanların bize ne dediğini gör",
  FeaturedIn: "Şurada yayınlandı",
  YouMightAlsoLike: "Bunları da beğenebilirsin",
  ViewCertificate: "Sertifik ı görüntüle",
  MoreInformation: "Daha fazla bilgi",
  Document: "Belge",
  PoweredBy: "Tarafından desteklenmektedir",
  Explore: "Keşfet",
  FollowUs: "Bizi takip et",
  Details: "Detaylar",
  Passport: "Pasaport",
  Certificates: "Sertifikalar",
  Socials: "Sosyal",
  Home: "Ana Sayfa",
  AboutUs: "Hakkımızda",
  TermsAndConditions: "Koşullar ve Şartlar",
  PrivacyPolicy: "Gizlilik Politikası",
  Reviews: "Yorumlar",
  NutritionalInformation: "Besin Bilgisi",
  Videos: "Videolar",
  SocialMedia: "Sosyal Medya",
  Attributes: "Özellikler",
  MoreReviews: "Daha fazla yorum",
  AvailableAt: "Şurada mevcut",
  Nutrition: "Beslenme",
  Competition: "Yarışma",
  Loyalty: "Sadakat",
  Promotions: "Promosyonlar",
  ThankYouForYourSubmission:
    "Gönderiniz için teşekkür ederiz. Bloğunuzu kontrol edeceğiz ve katılım onayını e-posta ile göndereceğiz.",
  Submit: "Gönder",
  UploadYourReceipt: "Fişinizi yükleyin",
  SupplyChainStory: "Tedarik zinciri hikayesi",
  View: "Görüntüle",
  ViewProducts: "Ürünleri Görüntüle",
  ViewMap: "Haritayı Görüntüle",
  ViewMore: "Daha Fazla Görüntüle",
  ViewLess: "Daha Az Görüntüle",
  SeeThisProductsJourney: "Bu ürünün yolculuğunu gör",
  ProductJourney: "Ürün Yolculuğu",
  InteractiveMap: "Etkileşimli Harita",
  ViewSupplyChain: "Tedarik Zincirini Görüntüle",
  BatchSpecificCertificates: "Partiözel sertifikalar",
  CompleteChain: "Tam Zincir",
  BatchLookupFailedTryAgainLater: "Parti araması başarısız oldu, daha sonra tekrar deneyin.",
}
