// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Can't find a batch number?",
  GetBatchNumber: "Get batch number",
  InputBatchNumber: "Input batch number",
  ProductTracking: "Product Tracking",
  IngredientsTracking: "Ingredients Tracking",
  IngredientsStory: "Ingredients Story",
  ProductDetails: "Product Details",
  SeeMyJourney: "See My Journey",
  WhatPeopleSaidAboutUs: "What people said about us",
  FeaturedIn: "Featured In",
  YouMightAlsoLike: "You might also like",
  ViewCertificate: "View Certificate",
  MoreInformation: "More Information",
  Document: "Document",
  PoweredBy: "Powered by",
  Explore: "Explore",
  FollowUs: "Follow us",
  // New Landing page / Template 5
  Details: "Details",
  Passport: "Passport",
  Certificates: "Certificates",
  Socials: "Socials",
  Home: "Home",
  AboutUs: "About us",
  TermsAndConditions: "Terms & conditions",
  PrivacyPolicy: "Privacy policy",
  Reviews: "Reviews",
  NutritionalInformation: "Nutritional Information",
  Videos: "Videos",
  SocialMedia: "Social Media",
  Attributes: "Attributes",
  MoreReviews: "More Reviews",
  AvailableAt: "Available at",
  Nutrition: "Nutrition",
  Competition: "Competition",
  Loyalty: "Loyalty",
  Promotions: "Promotions",
  ThankYouForYourSubmission:
    "Thank you for your submission. Your receipt will be verified and confirmation of your entry will be emailed to you.",
  Submit: "Submit",
  UploadYourReceipt: "Upload Your Receipt",
  SupplyChainStory: "Supply Chain Story",
  View: "View",
  ViewProducts: "View Products",
  ViewMap: "View Map",
  ViewMore: "View More",
  ViewLess: "View Less",
  SeeThisProductsJourney: "See this product's journey",
  ProductJourney: "Product Journey",
  InteractiveMap: "Interactive Map",
  ViewSupplyChain: "View Supply Chain",
  BatchSpecificCertificates: "Batch specific certificates",
  CompleteChain: "Complete Chain",
  BatchLookupFailedTryAgainLater: "Batch lookup failed, try again later.",
}
