// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Kann keine Chargennummer finden?",
  GetBatchNumber: "Chargennummer erhalten",
  InputBatchNumber: "Chargennummer eingeben",
  ProductTracking: "Produktverfolgung",
  IngredientsTracking: "Zutatenverfolgung",
  IngredientsStory: "Zutatengeschichte",
  ProductDetails: "Produktdetails",
  SeeMyJourney: "Sehen Sie meine Reise",
  WhatPeopleSaidAboutUs: "Was Leute über uns gesagt haben",
  FeaturedIn: "Vorgestellt in",
  YouMightAlsoLike: "Das könnte Ihnen auch gefallen",
  ViewCertificate: "Zertifikat anzeigen",
  MoreInformation: "Mehr Informationen",
  Document: "Dokument",
  PoweredBy: "Angetrieben von",
  Explore: "Erkunden",
  FollowUs: "Folge uns",
  // New Landing page / Template 5
  Details: "Details",
  Passport: "Pass",
  Certificates: "Zertifikate",
  Socials: "Soziale Medien",
  Home: "Zuhause",
  AboutUs: "Über uns",
  TermsAndConditions: "Geschäftsbedingungen",
  PrivacyPolicy: "Datenschutz-Bestimmungen",
  Reviews: "Bewertungen",
  NutritionalInformation: "Nährwertinformationen",
  Videos: "Videos",
  SocialMedia: "Soziale Medien",
  Attributes: "Attribute",
  MoreReviews: "Weitere Bewertungen",
  AvailableAt: "Erhältlich bei",
  Nutrition: "Ernährung",
  Competition: "Wettbewerb",
  Loyalty: "Treue",
  Promotions: "Aktionen",
  ThankYouForYourSubmission:
    "Vielen Dank für Ihre Einreichung. Ihr Beleg wird überprüft und die Bestätigung Ihrer Teilnahme wird Ihnen per E-Mail zugesandt.",
  Submit: "Einreichen",
  UploadYourReceipt: "Laden Sie Ihren Beleg hoch",
  SupplyChainStory: "Lieferkettengeschichte",
  View: "Anzeigen",
  ViewProducts: "Produkte anzeigen",
  ViewMap: "Karte anzeigen",
  ViewMore: "Mehr anzeigen",
  ViewLess: "Weniger anzeigen",
  SeeThisProductsJourney: "Sehen Sie die Reise dieses Produkts",
  ProductJourney: "Produktreise",
  InteractiveMap: "Interaktive Karte",
  ViewSupplyChain: "Lieferkette anzeigen",
  BatchSpecificCertificates: "Chargenspezifische Zertifikate",
  CompleteChain: "Komplette Kette",
  BatchLookupFailedTryAgainLater: "Chargensuche fehlgeschlagen, versuchen Sie es später erneut.",
}
