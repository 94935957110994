// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Kan inte hitta batchnummer",
  GetBatchNumber: "Få batchnummer",
  InputBatchNumber: "Skriv in batchnummer",
  ProductTracking: "Produkt Spårning",
  IngredientsTracking: "Ingrediens Spårning",
  IngredientsStory: "Ingrediens Historia",
  ProductDetails: "Produkt Detaljer",
  SeeMyJourney: "Se min resa",
  WhatPeopleSaidAboutUs: "Vad folk sa om oss",
  FeaturedIn: "Publicerad i",
  YouMightAlsoLike: "Du kanske också gillar",
  ViewCertificate: "Visa certifikat",
  MoreInformation: "Mer information",
  Document: "Dokument",
  PoweredBy: "Drivs av",
  Explore: "Utforska",
  FollowUs: "Följ oss",
  // New Landing page / Template 5
  Details: "Detaljer",
  Passport: "Pass",
  Certificates: "Certifikat",
  Socials: "Sociala",
  Home: "Hem",
  AboutUs: "Om oss",
  TermsAndConditions: "Villkor och bestämmelser",
  PrivacyPolicy: "Integritetspolicy",
  Reviews: "Recensioner",
  NutritionalInformation: "Närings information",
  Videos: "Videor",
  SocialMedia: "Sociala medier",
  Attributes: "Attribut",
  MoreReviews: "Fler recensioner",
  AvailableAt: "Tillgänglig på",
  Nutrition: "Näring",
  Competition: "Tävling",
  Loyalty: "Lojalitet",
  Promotions: "Kampanjer",
  ThankYouForYourSubmission:
    "Tack för din insändning. Vi kommer att granska din block och skicka bekräftelsen på deltagandet via e-post.",
  Submit: "Skicka",
  UploadYourReceipt: "Ladda upp ditt kvitto",
  SupplyChainStory: "Leveranskedja historia",
  View: "Visa",
  ViewProducts: "Visa produkter",
  ViewMap: "Visa karta",
  ViewMore: "Visa mer",
  ViewLess: "Visa mindre",
  SeeThisProductsJourney: "Se denna produkts resa",
  ProductJourney: "Produktresa",
  InteractiveMap: "Interaktiv karta",
  ViewSupplyChain: "Visa leveranskedjan",
  BatchSpecificCertificates: "Batchspecifika certifikat",
  CompleteChain: "Fullständig kedja",
  BatchLookupFailedTryAgainLater: "Batchuppslag misslyckades, försök igen senare",
}
