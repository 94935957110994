import React from "react"

import closeIcon from "@/assets/images/close_icon.svg"

import styles from "./styles.module.css"

interface PopupProps {
  scrollingContent?: boolean
  positionOnTop?: boolean
  maxWidth?: number | string
  maxHeight?: number | string
  onCloseClick(): void
  onBackgroundClick?(): void
  children?: JSX.Element | JSX.Element[]
  footer?: JSX.Element
  removeHeader?: boolean
  title?: string
  customPopupClass?: string
}

export const Popup = (props: PopupProps) => {
  const {
    scrollingContent,
    positionOnTop,
    maxWidth,
    maxHeight,
    onCloseClick,
    onBackgroundClick,
    children,
    footer,
    removeHeader,
    title,
    customPopupClass,
  } = props
  const popupDialogRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    function handleClickOutside(e: any) {
      if (popupDialogRef.current && !popupDialogRef.current.contains(e.target)) {
        onBackgroundClick ? onBackgroundClick() : onCloseClick()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [onBackgroundClick, onCloseClick, popupDialogRef])

  return (
    <div
      className={`${styles.popupWrapper} ${customPopupClass ? customPopupClass : "popup"}`}
      style={{ justifyContent: scrollingContent ? "center" : "flex-start" }}
    >
      <div
        ref={popupDialogRef}
        className={`${styles.popupDialog} ${scrollingContent && styles.popupDialogScrolling} popupDialog`}
        style={{
          margin: positionOnTop ? 0 : "auto",
          maxWidth: maxWidth || 720,
          maxHeight: maxHeight || "unset",
        }}
      >
        {!removeHeader && (
          <div className={`${styles.popupHeader}`}>
            {title && <div className={styles.popupTitle}>{title}</div>}
            <img className={`${styles.popupClose}`} src={closeIcon} alt='Close' onClick={() => onCloseClick()} />
          </div>
        )}
        <div
          className={`${styles.popupBody} customScrollbar`}
          style={{
            paddingBottom: footer ? 0 : 24,
            overflow: scrollingContent ? "auto" : "unset",
          }}
        >
          {children && <React.Fragment>{children}</React.Fragment>}
        </div>
        {footer && <div className={styles.popupFooter}>{footer}</div>}
      </div>
    </div>
  )
}

export default Popup
