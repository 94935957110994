// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Nie można znaleźć numeru partii",
  GetBatchNumber: "Pobierz numer partii",
  InputBatchNumber: "Wprowadź numer partii",
  ProductTracking: "Śledzenie produktu",
  IngredientsTracking: "Śledzenie składników",
  IngredientsStory: "Historia składników",
  ProductDetails: "Szczegóły produktu",
  SeeMyJourney: "Zobacz moją podróż",
  WhatPeopleSaidAboutUs: "Co ludzie mówili o nas",
  FeaturedIn: "Opublikowano w",
  YouMightAlsoLike: "Może ci się spodobać",
  ViewCertificate: "Zobacz certyfikat",
  MoreInformation: "Więcej informacji",
  Document: "Dokument",
  PoweredBy: "Napędzane przez",
  Explore: "Odkryj",
  FollowUs: "Zaobserwuj nas",
  // New Landing page / Template 5
  Details: "Szczegóły",
  Passport: "Paszport",
  Certificates: "Certyfikaty",
  Socials: "Społecznościowe",
  Home: "Strona główna",
  AboutUs: "O nas",
  TermsAndConditions: "Warunki i zasady",
  PrivacyPolicy: "Polityka prywatności",
  Reviews: "Recenzje",
  NutritionalInformation: "Informacje żywieniowe",
  Videos: "Filmy",
  SocialMedia: "Media społecznościowe",
  Attributes: "Atrybuty",
  MoreReviews: "Więcej recenzji",
  AvailableAt: "Dostępne w",
  Nutrition: "Odżywianie",
  Competition: "Konkurencja",
  Loyalty: "Lojalność",
  Promotions: "Promocje",
  ThankYouForYourSubmission:
    "Dziękujemy za przesłanie. Sprawdzimy Twój blok i wyślemy potwierdzenie uczestnictwa e-mailem.",
  Submit: "Prześlij",
  UploadYourReceipt: "Prześlij swoje potwierdzenie",
  SupplyChainStory: "Historia łańcucha dostaw",
  View: "Zobacz",
  ViewProducts: "Zobacz produkty",
  ViewMap: "Zobacz mapę",
  ViewMore: "Zobacz więcej",
  ViewLess: "Zobacz mniej",
  SeeThisProductsJourney: "Zobacz podróż tego produktu",
  ProductJourney: "Podróż produktu",
  InteractiveMap: "Interaktywna mapa",
  ViewSupplyChain: "Zobacz łańcuch dostaw",
  BatchSpecificCertificates: "Certyfikaty partii",
  CompleteChain: "Pełny łańcuch",
  BatchLookupFailedTryAgainLater: "Wyszukiwanie partii nie powiodło się, spróbuj ponownie później.",
}
