// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Impossible de trouver un numéro de lot?",
  GetBatchNumber: "Obtenir un numéro de lot",
  InputBatchNumber: "Entrer un numéro de lot",
  ProductTracking: "Suivi du produit",
  IngredientsTracking: "Suivi des ingrédients",
  IngredientsStory: "Histoire des ingrédients",
  ProductDetails: "Détails du produit",
  SeeMyJourney: "Voir mon voyage",
  WhatPeopleSaidAboutUs: "Ce que les gens ont dit de nous",
  FeaturedIn: "En vedette dans",
  YouMightAlsoLike: "Vous pourriez aussi aimer",
  ViewCertificate: "Voir le certificat",
  MoreInformation: "Plus d'informations",
  Document: "Document",
  PoweredBy: "Propulsé par",
  Explore: "Explorer",
  FollowUs: "Suivez-nous",
  // New Landing page / Template 5
  Details: "Détails",
  Passport: "Passeport",
  Certificates: "Certificats",
  Socials: "Réseaux sociaux",
  Home: "Accueil",
  AboutUs: "À propos de nous",
  TermsAndConditions: "Termes et conditions",
  PrivacyPolicy: "Politique de confidentialité",
  Reviews: "Avis",
  NutritionalInformation: "Informations nutritionnelles",
  Videos: "Vidéos",
  SocialMedia: "Médias sociaux",
  Attributes: "Attributs",
  MoreReviews: "Plus d'avis",
  AvailableAt: "Disponible chez",
  Nutrition: "Nutrition",
  Competition: "Concurrence",
  Loyalty: "Fidélité",
  Promotions: "Promotions",
  ThankYouForYourSubmission:
    "Merci pour votre soumission Votre reçu sera vérifié et la confirmation de votre participation vous sera envoyée par e-mail.",
  Submit: "Soumettre",
  UploadYourReceipt: "Téléchargez votre reçu",
  SupplyChainStory: "Histoire de la chaîne d'approvisionnement",
  View: "Voir",
  ViewProducts: "Voir les produits",
  ViewMap: "Voir la carte",
  ViewMore: "Voir plus",
  ViewLess: "Voir moins",
  SeeThisProductsJourney: "Voir le voyage de ce produit",
  ProductJourney: "Voyage du produit",
  InteractiveMap: "Carte interactive",
  ViewSupplyChain: "Voir la chaîne d'approvisionnement",
  BatchSpecificCertificates: "Certificats spécifiques au lot",
  CompleteChain: "Chaîne complète",
  BatchLookupFailedTryAgainLater: "La recherche de lot a échoué, réessayez plus tard.",
}
