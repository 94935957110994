/* *
 * Pull all launch settings and hardcoded configuration options from here.
 */

/* Environment Variables - make sure all of these are set in `.env` to ensure they're not undefined (!) */
export const BACKEND_DOMAIN_PRIMARY = process.env.REACT_APP_BACKEND_DOMAIN_PRIMARY!
export const BACKEND_DOMAIN_STAGING = process.env.REACT_APP_BACKEND_DOMAIN_STAGING!
export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY!
export const AMPLITUDE_API_KEY_PROD = process.env.REACT_APP_AMPLITUDE_API_KEY_PROD!
export const GOOGLE_APIKEY = process.env.REACT_APP_GOOGLE_APIKEY!
export const FALLBACK_GIF = process.env.REACT_APP_FALLBACK_GIF!
export const CLOSE_NOW_GIF = process.env.REACT_APP_CLOSE_NOW_GIF!
export const PREACTIVATION_IMG = process.env.REACT_APP_PREACTIVATION_IMG!
export const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN!
export const CLOSE_ENDPOINT = process.env.REACT_APP_CLOSE_ENDPOINT!
export const REST_VIEW = process.env.REACT_APP_REST_VIEW!
export const QUERY_API = process.env.REACT_APP_QUERY_API!
export const CLOUD_FUNCTIONS_API = process.env.REACT_APP_CLOUD_FUNCTIONS_API!

/* Transformed Environment Vars */
const FRONTEND_DOMAIN = process.env.REACT_APP_FRONTEND_DOMAIN!
const FRONTEND_CLOSE_PAGE = process.env.REACT_APP_FRONTEND_CLOSE_PAGE!
export const CLOSE_PAGE_URL = FRONTEND_DOMAIN + FRONTEND_CLOSE_PAGE

const _DEVMODE = process.env.REACT_APP_DEVMODE
export const DEVMODE: boolean = !!_DEVMODE && ["true", "yes", "1"].includes(_DEVMODE.toLowerCase())

const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY!
const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID!
const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!
const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!
const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID!
const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!
/**
 * Your web app's Firebase configuration
 * For Firebase JS SDK v7.20.0 and later, measurementId is optional
 */
export const FirebaseJson = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
}

/* Hardcoded Constants */
export const DOWNLOAD_APP_LINK = "https://orijinplusdownload.page.link/get-in-app"

export enum LocalStorageKeys {
  LAST_CLOSE = "last_close",
}

/* Other Configurations */
const productionDomains: string[] = ["https://qr.orijin.plus/"]
const stagingDomains: string[] = ["https://qr-staging.orijin.plus/"]
/**
 * This is just a dumb check to see where the app is being hosted and whether the host url matches the expected production hosting url.
 * Do not mix this up with the EnvironmentContainer.useContainer().isStaging() hook which checks which backend the current page is pulling data from.
 */
export const ENV: "production" | "staging" | "development" = productionDomains.find(
  (x) => x.indexOf(window.location.origin) === 0,
)
  ? "production"
  : stagingDomains.find((x) => x.indexOf(window.location.origin) === 0)
  ? "staging"
  : "development"

export const DefaultFirebaseConfig = {
  landing_scan_now_fallback_gif: "https://assets.orijin.plus/tear_off_animation.gif",
  landing_scan_now_fallback_video: "https://assets.orijin.plus/tear_off_animation.mp4",
  landing_scan_now_button_text: "SCAN HIDDEN QR NOW",
  landing_scan_now_button_enabled: false,
  landing_close_now_gif: "https://assets.orijin.plus/one-code-close-now.gif",
  landing_close_now_button_text: "CLOSE NOW",
  landing_page_header: `AUTHENTICATE
    EARN, WIN`,
  landing_page_preactivation_image: "https://assets.orijin.plus/preactivation-long-image.png",

  no_scan_error_delay: 12,
  no_scan_error_header: "Looks like we're having trouble scanning!",
  no_scan_error_message: "Try using your camera app instead.",
  no_scan_error_button_text: "TAKE PHOTO",

  repeate_close_warning_header: "WARNING",
  repeate_close_warning_message: "If you already have an account you must use the mobile app to claim any more points!",
  repeate_close_warning_button_text: "Download Orijin Plus Now",

  failed_photo_scan_warning_header: "WARNING",
  failed_photo_scan_warning_message: "Seems the image was not clear enough, can you please try again.",

  closed_page_prize_popup_delay: 5,
  closed_page_show_popup_multiple_times: false,
}
