// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Kan ikke finde et batchnummer",
  GetBatchNumber: "Hent batchnummer",
  InputBatchNumber: "Indtast batchnummer",
  ProductTracking: "Produkt sporing",
  IngredientsTracking: "Ingrediens sporing",
  IngredientsStory: "Ingrediens historie",
  ProductDetails: "Produkt detaljer",
  SeeMyJourney: "Se min rejse",
  WhatPeopleSaidAboutUs: "Hvad folk sagde om os",
  FeaturedIn: "Fremhævet i",
  YouMightAlsoLike: "Du kan også lide",
  ViewCertificate: "Vis certifikat",
  MoreInformation: "Mere information",
  Document: "Dokument",
  PoweredBy: "Drevet af",
  Explore: "Udforsk",
  FollowUs: "Følg os",
  // New Landing page / Template 5
  Details: "Detaljer",
  Passport: "Pas",
  Certificates: "Certifikater",
  Socials: "Sociale medier",
  Home: "Hjem",
  AboutUs: "Om os",
  TermsAndConditions: "Vilkår og betingelser",
  PrivacyPolicy: "Fortrolighedspolitik",
  Reviews: "Anmeldelser",
  NutritionalInformation: "Ernæringsmæssige oplysninger",
  Videos: "Videoer",
  SocialMedia: "Sociale medier",
  Attributes: "Attributter",
  MoreReviews: "Flere anmeldelser",
  AvailableAt: "Tilgængelig på",
  Nutrition: "Ernæring",
  Competition: "Konkurrence",
  Loyalty: "Loyalitet",
  Promotions: "Kampagner",
  ThankYouForYourSubmission:
    "Tak for din indsendelse. Din kvittering vil blive verificeret, og bekræftelse af din indtastning vil blive sendt til dig via e-mail.",
  Submit: "Indsend",
  UploadYourReceipt: "Upload din kvittering",
  SupplyChainStory: "Historie om forsyningskæden",
  // View: "View",
  // ViewProducts: "View Products",
  // ViewMap: "View Map",
  // ViewMore: "View More",
  // ViewLess: "View Less",
  // SeeThisProductsJourney: "See this product's journey",
  // ProductJourney: "Product Journey",
  // InteractiveMap: "Interactive Map",
  // ViewSupplyChain: "View Supply Chain",
  // BatchSpecificCertificates: "Batch specific certificates",
  // CompleteChain: "Complete Chain",
  // BatchLookupFailedTryAgainLater: "Batch lookup failed, try again later.",
  View: "Vis",
  ViewProducts: "Vis produkter",
  ViewMap: "Vis kort",
  ViewMore: "Vis mere",
  ViewLess: "Vis mindre",
  SeeThisProductsJourney: "Se denne produkts rejse",
  ProductJourney: "Produkt rejse",
  InteractiveMap: "Interaktivt kort",
  ViewSupplyChain: "Vis forsyningskæden",
  BatchSpecificCertificates: "Batchspecifikke certifikater",
  CompleteChain: "Komplet kæde",
  BatchLookupFailedTryAgainLater: "Batchopslag mislykkedes, prøv igen senere.",
}
