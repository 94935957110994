// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Tidak dapat menemukan nomor batch?",
  GetBatchNumber: "Dapatkan nomor batch",
  InputBatchNumber: "Masukkan nomor batch",
  ProductTracking: "Pelacakan Produk",
  IngredientsTracking: "Pelacakan Bahan",
  IngredientsStory: "Kisah Bahan",
  ProductDetails: "Detail Produk",
  SeeMyJourney: "Lihat Perjalanan Saya",
  WhatPeopleSaidAboutUs: "Apa yang orang katakan tentang kami",
  FeaturedIn: "Ditampilkan di",
  YouMightAlsoLike: "Anda mungkin juga menyukai",
  ViewCertificate: "Lihat Sertifikat",
  MoreInformation: "Informasi Lebih Lanjut",
  Document: "Dokumen",
  PoweredBy: "Didukung oleh",
  Explore: "Jelajahi",
  FollowUs: "Ikuti kami",
  // New Landing page / Template 5
  Details: "Detail",
  Passport: "Paspor",
  Certificates: "Sertifikat",
  Socials: "Sosial",
  Home: "Beranda",
  AboutUs: "Tentang kami",
  TermsAndConditions: "Syarat & Ketentuan",
  PrivacyPolicy: "Kebijakan Privasi",
  Reviews: "Ulasan",
  NutritionalInformation: "Informasi Nutrisi",
  Videos: "Video",
  SocialMedia: "Media Sosial",
  Attributes: "Atribut",
  MoreReviews: "Ulasan Lainnya",
  AvailableAt: "Tersedia di",
  Nutrition: "Nutrisi",
  Competition: "Kompetisi",
  Loyalty: "Loyalitas",
  Promotions: "Promosi",
  ThankYouForYourSubmission:
    "Terima kasih atas pengajuan Anda. Tanda terima Anda akan diverifikasi dan konfirmasi partisipasi Anda akan dikirimkan melalui email.",
  Submit: "Kirim",
  UploadYourReceipt: "Unggah Tanda Terima Anda",
  SupplyChainStory: "Kisah Rantai Pasok",
  View: "Lihat",
  ViewProducts: "Lihat Produk",
  ViewMap: "Lihat Peta",
  ViewMore: "Lihat Lebih Banyak",
  ViewLess: "Lihat Lebih Sedikit",
  SeeThisProductsJourney: "Lihat perjalanan produk ini",
  ProductJourney: "Perjalanan Produk",
  InteractiveMap: "Peta Interaktif",
  ViewSupplyChain: "Lihat Rantai Pasok",
  BatchSpecificCertificates: "Sertifikat Khusus Batch",
  CompleteChain: "Rantai Lengkap",
  BatchLookupFailedTryAgainLater: "Pencarian batch gagal, coba lagi nanti.",
}
