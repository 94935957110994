// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Non riesci a trovare un numero di lotto?",
  GetBatchNumber: "Ottieni un numero di lotto",
  InputBatchNumber: "Inserisci un numero di lotto",
  ProductTracking: "Tracciamento del prodotto",
  IngredientsTracking: "Tracciamento degli ingredienti",
  IngredientsStory: "Storia degli ingredienti",
  ProductDetails: "Dettagli del prodotto",
  SeeMyJourney: "Vedi il mio viaggio",
  WhatPeopleSaidAboutUs: "Cosa hanno detto di noi",
  FeaturedIn: "In primo piano in",
  YouMightAlsoLike: "Potrebbe piacerti anche",
  ViewCertificate: "Visualizza certificato",
  MoreInformation: "Maggiori informazioni",
  Document: "Documento",
  PoweredBy: "Powered by",
  Explore: "Esplora",
  FollowUs: "Seguici",
  // New Landing page / Template 5
  Details: "Dettagli",
  Passport: "Passaporto",
  Certificates: "Certificati",
  Socials: "Social",
  Home: "Casa",
  AboutUs: "Chi siamo",
  TermsAndConditions: "Termini e condizioni",
  PrivacyPolicy: "Informativa sulla privacy",
  Reviews: "Recensioni",
  NutritionalInformation: "Informazioni nutrizionali",
  Videos: "Video",
  SocialMedia: "Social media",
  Attributes: "Attributi",
  MoreReviews: "Altre recensioni",
  AvailableAt: "Disponibile a",
  Nutrition: "Nutrizione",
  Competition: "Concorrenza",
  Loyalty: "Loyalty",
  Promotions: "Promozioni",
  ThankYouForYourSubmission:
    "Grazie per la tua presentazione. Il tuo scontrino verrà verificato e la conferma della tua partecipazione ti verrà inviata via e-mail.",
  Submit: "Invia",
  UploadYourReceipt: "Carica il tuo scontrino",
  SupplyChainStory: "Storia della catena di approvvigionamento",
  View: "Visualizza",
  ViewProducts: "Visualizza prodotti",
  ViewMap: "Visualizza mappa",
  ViewMore: "Visualizza altro",
  ViewLess: "Visualizza meno",
  SeeThisProductsJourney: "Vedi il viaggio di questo prodotto",
  ProductJourney: "Viaggio del prodotto",
  InteractiveMap: "Mappa interattiva",
  ViewSupplyChain: "Visualizza catena di approvvigionamento",
  BatchSpecificCertificates: "Certificati specifici del lotto",
  CompleteChain: "Catena completa",
  BatchLookupFailedTryAgainLater: "Ricerca batch non riuscita, riprova più tardi.",
}
