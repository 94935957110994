// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Không thể tìm thấy số lô?",
  GetBatchNumber: "Nhận số lô",
  InputBatchNumber: "Nhập số lô",
  ProductTracking: "Theo dõi sản phẩm",
  IngredientsTracking: "Theo dõi thành phần",
  IngredientsStory: "Chuyện thành phần",
  ProductDetails: "Chi tiết sản phẩm",
  SeeMyJourney: "Xem hành trình của tôi",
  WhatPeopleSaidAboutUs: "Người ta nói gì về chúng tôi",
  FeaturedIn: "Được giới thiệu trong",
  YouMightAlsoLike: "Bạn cũng có thể thích",
  ViewCertificate: "Xem chứng chỉ",
  MoreInformation: "Thông tin thêm",
  Document: "Tài liệu",
  PoweredBy: "Được cung cấp bởi",
  Explore: "Khám phá",
  FollowUs: "Theo dõi chúng tôi",
  // New Landing page / Template 5
  Details: "Chi tiết",
  Passport: "Hộ chiếu",
  Certificates: "Chứng chỉ",
  Socials: "Mạng xã hội",
  Home: "Trang chủ",
  AboutUs: "Về chúng tôi",
  TermsAndConditions: "Điều khoản & điều kiện",
  PrivacyPolicy: "Chính sách bảo mật",
  Reviews: "Đánh giá",
  NutritionalInformation: "Thông tin dinh dưỡng",
  Videos: "Video",
  SocialMedia: "Mạng xã hội",
  Attributes: "Thuộc tính",
  MoreReviews: "Đánh giá thêm",
  AvailableAt: "Có sẵn tại",
  Nutrition: "Dinh dưỡng",
  Competition: "Cuộc thi",
  Loyalty: "Sự trung thành",
  Promotions: "Khuyến mãi",
  ThankYouForYourSubmission:
    "Cảm ơn bạn đã gửi. Hóa đơn của bạn sẽ được xác minh và xác nhận tham gia sẽ được gửi qua email.",
  Submit: "Gửi",
  UploadYourReceipt: "Tải lên hóa đơn của bạn",
  SupplyChainStory: "Chuyện chuỗi cung ứng",
  View: "Xem",
  ViewProducts: "Xem sản phẩm",
  ViewMap: "Xem bản đồ",
  ViewMore: "Xem thêm",
  ViewLess: "Xem ít hơn",
  SeeThisProductsJourney: "Xem hành trình của sản phẩm này",
  ProductJourney: "Hành trình sản phẩm",
  InteractiveMap: "Bản đồ tương tác",
  ViewSupplyChain: "Xem chuỗi cung ứng",
  BatchSpecificCertificates: "Chứng chỉ cụ thể của lô",
  CompleteChain: "Chuỗi hoàn chỉnh",
  BatchLookupFailedTryAgainLater: "Tìm kiếm lô thất bại, thử lại sau.",
}
