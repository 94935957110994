// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Tidak dapat mencari nombor lot",
  GetBatchNumber: "Dapatkan nombor lot",
  InputBatchNumber: "Masukkan nombor lot",
  ProductTracking: "Penjejak produk",
  IngredientsTracking: "Penjejak bahan",
  IngredientsStory: "Kisah bahan",
  ProductDetails: "Butiran produk",
  SeeMyJourney: "Lihat perjalanan saya",
  WhatPeopleSaidAboutUs: "Apa yang orang kata tentang kami",
  FeaturedIn: "Ditampilkan di",
  YouMightAlsoLike: "Anda mungkin juga suka",
  ViewCertificate: "Lihat sijil",
  MoreInformation: "Maklumat lanjut",
  Document: "Dokumen",
  PoweredBy: "Dikuasakan oleh",
  Explore: "Terokai",
  FollowUs: "Ikuti kami",
  // New Landing page / Template 5
  Details: "Butiran",
  Passport: "Pasport",
  Certificates: "Sijil",
  Socials: "Sosial",
  Home: "Laman Utama",
  AboutUs: "Tentang Kami",
  TermsAndConditions: "Terma & Syarat",
  PrivacyPolicy: "Dasar Privasi",
  Reviews: "Ulasan",
  NutritionalInformation: "Maklumat Pemakanan",
  Videos: "Video",
  SocialMedia: "Media Sosial",
  Attributes: "Atribut",
  MoreReviews: "Ulasan Lanjut",
  AvailableAt: "Tersedia di",
  Nutrition: "Nutrisi",
  Competition: "Peraduan",
  Loyalty: "Kesetiaan",
  Promotions: "Promosi",
  ThankYouForYourSubmission:
    "Terima kasih kerana menghantar. Resit anda akan disahkan dan pengesahan penyertaan anda akan dihantar melalui e-mel.",
  Submit: "Hantar",
  UploadYourReceipt: "Muat naik resit anda",
  SupplyChainStory: "Kisah Rantai Bekalan",
  View: "Lihat",
  ViewProducts: "Lihat Produk",
  ViewMap: "Lihat Peta",
  ViewMore: "Lihat Lebih",
  ViewLess: "Lihat Kurang",
  SeeThisProductsJourney: "Lihat perjalanan produk ini",
  ProductJourney: "Perjalanan Produk",
  InteractiveMap: "Peta Interaktif",
  ViewSupplyChain: "Lihat Rantai Bekalan",
  BatchSpecificCertificates: "Sijil Khusus Batch",
  CompleteChain: "Rantai Lengkap",
  BatchLookupFailedTryAgainLater: "Pencarian lot gagal, cuba lagi kemudian.",
}
