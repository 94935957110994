import React from "react"

import { createContainer } from "unstated-next"

import Popup from "@/components/Popup"

const PopupManager = () => {
  const [current, setCurrent] = React.useState<JSX.Element | undefined>()
  const popupQueue = React.useRef<JSX.Element[]>([])

  function createPopup(contents: JSX.Element | JSX.Element[], onClose?: () => void, ignoreBackgroundClick?: true) {
    const handleClose = () => {
      if (onClose) onClose()
      setCurrent(undefined)
    }

    const handleBackgroundClick = ignoreBackgroundClick ? () => {} : handleClose

    return (
      <Popup onCloseClick={handleClose} onBackgroundClick={handleBackgroundClick}>
        {contents}
      </Popup>
    )
  }

  function addPopup(contents: JSX.Element | JSX.Element[], onClose?: () => void, ignoreBackgroundClick?: true) {
    const newPopup = createPopup(contents, onClose, ignoreBackgroundClick)

    if (current === undefined) {
      popupQueue.current.push(newPopup)
      setCurrent(newPopup)
    } else {
      popupQueue.current.push(newPopup)
    }
  }

  function pushPopup(contents: JSX.Element | JSX.Element[], onClose?: () => void, ignoreBackgroundClick?: true) {
    const newPopup = createPopup(contents, onClose, ignoreBackgroundClick)

    popupQueue.current.unshift(newPopup)
    setCurrent(newPopup)
  }

  function setOnlyPopup(contents: JSX.Element | JSX.Element[], onClose?: () => void, ignoreBackgroundClick?: true) {
    const newPopup = createPopup(contents, onClose, ignoreBackgroundClick)

    popupQueue.current = [newPopup]
    setCurrent(newPopup)
  }

  function closePopup() {
    setCurrent(undefined)
  }

  React.useEffect(() => {
    if (current === undefined) {
      popupQueue.current.shift()
      if (popupQueue.current.length > 0) {
        setCurrent(popupQueue.current[0])
      }
    }
  }, [current])

  return {
    popup: current,
    addPopup,
    pushPopup,
    setOnlyPopup,
    closePopup,
  }
}

export const PopupContainer = createContainer(PopupManager)
