// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "배치 번호를 찾을 수 없나요?",
  GetBatchNumber: "배치 번호 가져오기",
  InputBatchNumber: "배치 번호 입력",
  ProductTracking: "제품 추적",
  IngredientsTracking: "성분 추적",
  IngredientsStory: "성분 이야기",
  ProductDetails: "제품 세부 정보",
  SeeMyJourney: "내 여정 보기",
  WhatPeopleSaidAboutUs: "사람들이 우리에 대해 말한 것",
  FeaturedIn: "추천",
  YouMightAlsoLike: "당신도 좋아할지도 모릅니다",
  ViewCertificate: "인증서 보기",
  MoreInformation: "추가 정보",
  Document: "문서",
  PoweredBy: "제공자",
  Explore: "탐색",
  FollowUs: "팔로우",
  // New Landing page / Template 5
  Details: "세부 정보",
  Passport: "여권",
  Certificates: "증명서",
  Socials: "소셜",
  Home: "홈",
  AboutUs: "회사 소개",
  TermsAndConditions: "이용 약관",
  PrivacyPolicy: "개인 정보 보호 정책",
  Reviews: "리뷰",
  NutritionalInformation: "영양 정보",
  Videos: "비디오",
  SocialMedia: "소셜 미디어",
  Attributes: "속성",
  MoreReviews: "추가 리뷰",
  AvailableAt: "이용 가능",
  Nutrition: "영양",
  Competition: "경쟁",
  Loyalty: "충성",
  Promotions: "프로모션",
  ThankYouForYourSubmission: "제출해 주셔서 감사합니다. 영수증이 확인되고 참가 확인이 이메일로 전송됩니다.",
  Submit: "제출",
  UploadYourReceipt: "영수증 업로드",
  SupplyChainStory: "공급망 이야기",
  View: "보기",
  ViewProducts: "제품 보기",
  ViewMap: "지도 보기",
  ViewMore: "더 보기",
  ViewLess: "덜 보기",
  SeeThisProductsJourney: "이 제품의 여정 보기",
  ProductJourney: "제품 여정",
  InteractiveMap: "인터랙티브 지도",
  ViewSupplyChain: "공급망 보기",
  BatchSpecificCertificates: "배치별 인증서",
  CompleteChain: "완전한 체인",
  BatchLookupFailedTryAgainLater: "배치 조회 실패, 나중에 다시 시도하십시오.",
}
