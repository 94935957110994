// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "¿No puedes encontrar un número de lote?",
  GetBatchNumber: "Obtener número de lote",
  InputBatchNumber: "Introducir número de lote",
  ProductTracking: "Seguimiento del producto",
  IngredientsTracking: "Seguimiento de ingredientes",
  IngredientsStory: "Historia de ingredientes",
  ProductDetails: "Detalles del producto",
  SeeMyJourney: "Ver mi viaje",
  WhatPeopleSaidAboutUs: "Lo que la gente dijo sobre nosotros",
  FeaturedIn: "Destacado en",
  YouMightAlsoLike: "También te podría gustar",
  ViewCertificate: "Ver certificado",
  MoreInformation: "Más información",
  Document: "Documento",
  PoweredBy: "Impulsado por",
  Explore: "Explorar",
  FollowUs: "Síguenos",
  // New Landing page / Template 5
  Details: "Detalles",
  Passport: "Pasaporte",
  Certificates: "Certificados",
  Socials: "Redes sociales",
  Home: "Inicio",
  AboutUs: "Sobre nosotros",
  TermsAndConditions: "Términos y condiciones",
  PrivacyPolicy: "Política de privacidad",
  Reviews: "Reseñas",
  NutritionalInformation: "Información nutricional",
  Videos: "Videos",
  SocialMedia: "Redes sociales",
  Attributes: "Atributos",
  MoreReviews: "Más reseñas",
  AvailableAt: "Disponible en",
  Nutrition: "Nutrición",
  Competition: "Competencia",
  Loyalty: "Lealtad",
  Promotions: "Promociones",
  ThankYouForYourSubmission:
    "Gracias por tu envío. Tu recibo será verificado y la confirmación de tu participación se enviará por correo electrónico.",
  Submit: "Enviar",
  UploadYourReceipt: "Sube tu recibo",
  SupplyChainStory: "Historia de la cadena de suministro",
  View: "Ver",
  ViewProducts: "Ver productos",
  ViewMap: "Ver mapa",
  ViewMore: "Ver más",
  ViewLess: "Ver menos",
  SeeThisProductsJourney: "Ver el viaje de este producto",
  ProductJourney: "Viaje del producto",
  InteractiveMap: "Mapa interactivo",
  ViewSupplyChain: "Ver cadena de suministro",
  BatchSpecificCertificates: "Certificados específicos de lote",
  CompleteChain: "Cadena completa",
  BatchLookupFailedTryAgainLater: "La búsqueda de lote falló, inténtalo de nuevo más tarde.",
}
