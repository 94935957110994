// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "バッチ番号が見つかりませんか？",
  GetBatchNumber: "バッチ番号を取得",
  InputBatchNumber: "バッチ番号を入力",
  ProductTracking: "製品追跡",
  IngredientsTracking: "原材料追跡",
  IngredientsStory: "原材料ストーリー",
  ProductDetails: "製品詳細",
  SeeMyJourney: "私の旅を見る",
  WhatPeopleSaidAboutUs: "人々が私たちについて言ったこと",
  FeaturedIn: "で紹介",
  YouMightAlsoLike: "あなたも好きかもしれません",
  ViewCertificate: "証明書を表示",
  MoreInformation: "詳細情報",
  Document: "ドキュメント",
  PoweredBy: "搭載",
  Explore: "探検",
  FollowUs: "フォローする",
  // New Landing page / Template 5
  Details: "詳細",
  Passport: "パスポート",
  Certificates: "証明書",
  Socials: "ソーシャル",
  Home: "ホーム",
  AboutUs: "私たちについて",
  TermsAndConditions: "利用規約",
  PrivacyPolicy: "プライバシーポリシー",
  Reviews: "レビュー",
  NutritionalInformation: "栄養情報",
  Videos: "ビデオ",
  SocialMedia: "ソーシャルメディア",
  Attributes: "属性",
  MoreReviews: "他のレビュー",
  AvailableAt: "で利用可能",
  Nutrition: "栄養",
  Competition: "競争",
  Loyalty: "ロイヤリティ",
  Promotions: "プロモーション",
  ThankYouForYourSubmission: "ご提出いただきありがとうございます。領収書は確認され、参加の確認がメールで送信されます。",
  Submit: "提出",
  UploadYourReceipt: "レシートをアップロード",
  SupplyChainStory: "サプライチェーンストーリー",
  View: "表示",
  ViewProducts: "製品を表示",
  ViewMap: "地図を表示",
  ViewMore: "もっと見る",
  ViewLess: "もっと少なく見る",
  SeeThisProductsJourney: "この製品の旅を見る",
  ProductJourney: "製品の旅",
  InteractiveMap: "インタラクティブマップ",
  ViewSupplyChain: "サプライチェーンを表示",
  BatchSpecificCertificates: "バッチ固有の証明書",
  CompleteChain: "完全なチェーン",
  BatchLookupFailedTryAgainLater: "バッチの検索に失敗しました。後で再試行してください。",
}
