import i18next from "i18next"
import { initReactI18next } from "react-i18next"

import cs from "./cs"
import da from "./da"
import de from "./de"
import el from "./el"
import en from "./en"
import es from "./es"
import fr from "./fr"
import hu from "./hu"
import id from "./id"
import it from "./it"
import ja from "./ja"
import ko from "./ko"
import ms from "./ms"
import nl from "./nl"
import no from "./no"
import pl from "./pl"
import pt from "./pt"
import sv from "./sv"
import th from "./th"
import tr from "./tr"
import vi from "./vi"
import zh from "./zh"

export const defaultNS = "Common"
export const resources = {
  cs,
  da,
  de,
  el,
  en,
  es,
  fr,
  hu,
  id,
  it,
  ja,
  ko,
  ms,
  nl,
  no,
  pl,
  pt,
  sv,
  th,
  tr,
  vi,
  zh,
} as const

i18next.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  ns: Object.keys(en),
  defaultNS,
  resources,
})
