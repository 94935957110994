import React from "react"

import { FirebaseApp, initializeApp } from "firebase/app"
import { fetchAndActivate, getRemoteConfig, RemoteConfig } from "firebase/remote-config"
import { createContainer } from "unstated-next"

import { BACKEND_DOMAIN_PRIMARY, BACKEND_DOMAIN_STAGING, DefaultFirebaseConfig, FirebaseJson } from "@/Settings"

const Environment = () => {
  const productionBackends: string[] = ["https://api.orijin.plus/"]

  const stagingBackends: string[] = ["https://api-staging.orijin.plus/"]

  /* VARIABLES (can change during runtime) */
  const [BackendDomain, setBackendDomain] = React.useState(BACKEND_DOMAIN_PRIMARY)
  const [innerQueryEndpoint, innerSetQueryEndpoint] = React.useState(process.env.REACT_APP_QUERY_API!)
  const [innerCloseEndpoint, innerSetCloseEndpoint] = React.useState(process.env.REACT_APP_CLOSE_ENDPOINT!)
  const [DarkMode, setDarkMode] = React.useState(false)
  const ENV: "production" | "staging" | "development" = productionBackends.find((x) => x.indexOf(BackendDomain) === 0)
    ? "production"
    : stagingBackends.find((x) => x.indexOf(BackendDomain) === 0)
    ? "staging"
    : "development"

  // Firebase Remote Config
  const [isRemoteConfigLoaded, setConfigIsRemoteLoaded] = React.useState(false)
  function configFromFirebaseApp(app: FirebaseApp): RemoteConfig {
    const config = getRemoteConfig(app)
    config.settings.minimumFetchIntervalMillis = 1000 * 15 // 15 seconds // Don't reload config if time since last fetch is less than this long ago.
    config.defaultConfig = DefaultFirebaseConfig
    fetchAndActivate(config).then(() => {
      setConfigIsRemoteLoaded(true)
    })
    return config
  }
  const [firebaseApp] = React.useState(initializeApp(FirebaseJson))
  const [remoteConfig, setRemoteConfig] = React.useState<RemoteConfig>(configFromFirebaseApp(firebaseApp))
  React.useEffect(() => {
    setConfigIsRemoteLoaded(false)
    setRemoteConfig(configFromFirebaseApp(firebaseApp))
  }, [firebaseApp])

  // Validators
  function setStaging(active = true) {
    const value = active ? BACKEND_DOMAIN_STAGING : BACKEND_DOMAIN_PRIMARY
    setBackendDomain(value)
  }
  function isStaging() {
    return BackendDomain === BACKEND_DOMAIN_STAGING
  }

  function getQueryEndpoint() {
    return BackendDomain + innerQueryEndpoint
  }
  function setQueryEndpoint(value: string) {
    const url = new URL(value, BackendDomain)
    const backend = new URL(BackendDomain)
    if (url.origin === backend.origin) innerSetQueryEndpoint(url.pathname)
    else throw new Error("query endpoint must be on backend")
  }

  function getCloseEndpoint() {
    return BackendDomain + innerCloseEndpoint
  }
  function setCloseEndpoint(value: string) {
    const url = new URL(value, BackendDomain)
    const backend = new URL(BackendDomain)
    if (url.origin === backend.origin) innerSetCloseEndpoint(url.pathname)
    else throw new Error("close endpoint must be on backend")
  }

  return {
    BackendDomain,
    setBackendDomain,
    setStaging,
    isStaging,
    getQueryEndpoint,
    setQueryEndpoint,
    getCloseEndpoint,
    setCloseEndpoint,
    ENV,
    DarkMode,
    setDarkMode,
    firebaseApp,
    /** Access to the remote config values.
     *
     * Note: Wait for `isRemoteConfigLoaded` to be true or expect the value may change.
     * Things like timeouts should only be started after the remote config has finished loading.
     */
    remoteConfig,
    /** Similar to `ensureInitialized(RemoteConfig)` but only returns true after remote fetch has completed. Use this to ensure the values you are fetching are from the remote server, not the default values. */
    isRemoteConfigLoaded,
  }
}

export const EnvironmentContainer = createContainer(Environment)
