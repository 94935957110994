// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Nem található batch szám",
  GetBatchNumber: "Batch szám lekérése",
  InputBatchNumber: "Adja meg a batch számot",
  ProductTracking: "Termék követése",
  IngredientsTracking: "Összetevők követése",
  IngredientsStory: "Összetevők története",
  ProductDetails: "Termék információk",
  SeeMyJourney: "Nézd meg az utazásomat",
  WhatPeopleSaidAboutUs: "Mit mondtak rólunk az emberek",
  FeaturedIn: "Kiemelt",
  YouMightAlsoLike: "Ezek is tetszhetnek",
  ViewCertificate: "Tanúsítvány megtekintése",
  MoreInformation: "További információ",
  Document: "Dokumentum",
  PoweredBy: "Által támogatva",
  Explore: "Fedezd fel",
  FollowUs: "Kövess minket",
  // New Landing page / Template 5
  Details: "Részletek",
  Passport: "Útlevél",
  Certificates: "Tanúsítványok",
  Socials: "Közösségi",
  Home: "Kezdőlap",
  AboutUs: "Rólunk",
  TermsAndConditions: "Felhasználási feltételek",
  PrivacyPolicy: "Adatvédelmi irányelvek",
  Reviews: "Vélemények",
  NutritionalInformation: "Táplálkozási információk",
  Videos: "Videók",
  SocialMedia: "Közösségi média",
  Attributes: "Attribútumok",
  MoreReviews: "További vélemények",
  AvailableAt: "Elérhető itt",
  Nutrition: "Táplálkozás",
  Competition: "Verseny",
  Loyalty: "Hűség",
  Promotions: "Promóciók",
  ThankYouForYourSubmission:
    "Köszönjük a beküldését. A blokkját ellenőrizni fogjuk, és a részvétel megerősítését e-mailben elküldjük.",
  Submit: "Beküldés",
  UploadYourReceipt: "Töltse fel a blokkját",
  SupplyChainStory: "Ellátási lánc történet",
  View: "Megtekintés",
  ViewProducts: "Termékek megtekintése",
  ViewMap: "Térkép megtekintése",
  ViewMore: "Továbbiak megtekintése",
  ViewLess: "Kevesebb megtekintése",
  SeeThisProductsJourney: "Nézd meg ennek a terméknek az útját",
  ProductJourney: "Termék útja",
  InteractiveMap: "Interaktív térkép",
  ViewSupplyChain: "Ellátási lánc megtekintése",
  BatchSpecificCertificates: "Batch specifikus tanúsítványok",
  CompleteChain: "Teljes lánc",
  BatchLookupFailedTryAgainLater: "Batch keresés sikertelen, próbálja újra később.",
}
