import React from "react"

import { BaseProvider, LightTheme } from "baseui"
import ReactDOM from "react-dom/client"
import { Client as Styletron } from "styletron-engine-atomic"
import { Provider as StyletronProvider } from "styletron-react"

import App from "@/App"
import { EnvironmentContainer } from "@/controllers/Environment"
import { PopupContainer } from "@/controllers/PopupManager"
import Gs1Toolkit from "@/helpers/GS1DigitalLinkToolkit"

import "@/index.css"
import "@/locale/i18n"

const styletronEngine = new Styletron()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <StyletronProvider value={styletronEngine}>
      <BaseProvider theme={LightTheme}>
        <Gs1Toolkit.Provider>
          <EnvironmentContainer.Provider>
            <PopupContainer.Provider>
              <App />
            </PopupContainer.Provider>
          </EnvironmentContainer.Provider>
        </Gs1Toolkit.Provider>
      </BaseProvider>
    </StyletronProvider>
  </React.StrictMode>,
)
