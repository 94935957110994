// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Nemůžete najít číslo šarže?",
  GetBatchNumber: "Získat číslo šarže",
  InputBatchNumber: "Zadejte číslo šarže",
  ProductTracking: "Sledování produktu",
  IngredientsTracking: "Sledování ingrediencí",
  IngredientsStory: "Příběh ingrediencí",
  ProductDetails: "Podrobnosti o produktu",
  SeeMyJourney: "Podívejte se na moji cestu",
  WhatPeopleSaidAboutUs: "Co o nás lidé říkali",
  FeaturedIn: "Představeno v",
  YouMightAlsoLike: "Mohl by se vám také líbit",
  ViewCertificate: "Zobrazit certifikát",
  MoreInformation: "Více informací",
  Document: "Dokument",
  PoweredBy: "Poháněno",
  Explore: "Prozkoumat",
  FollowUs: "Sledujte nás",
  // New Landing page / Template 5
  Details: "Podrobnosti",
  Passport: "Pas",
  Certificates: "Certifikáty",
  Socials: "Sociální sítě",
  Home: "Domov",
  AboutUs: "O nás",
  TermsAndConditions: "Obchodní podmínky",
  PrivacyPolicy: "Zásady ochrany osobních údajů",
  Reviews: "Recenze",
  NutritionalInformation: "Nutriční informace",
  Videos: "Videa",
  SocialMedia: "Sociální média",
  Attributes: "Atributy",
  MoreReviews: "Další recenze",
  AvailableAt: "Dostupné na",
  Nutrition: "Výživa",
  Competition: "Soutěž",
  Loyalty: "Věrnost",
  Promotions: "Akce",
  ThankYouForYourSubmission:
    "Děkujeme za vaši přihlášku. Váš účet bude ověřen a potvrzení o vaší účasti vám bude zasláno e-mailem.",
  Submit: "Odeslat",
  UploadYourReceipt: "Nahrát váš účtenku",
  SupplyChainStory: "Příběh dodavatelského řetězce",
  View: "Zobrazit",
  ViewProducts: "Zobrazit produkty",
  ViewMap: "Zobrazit mapu",
  ViewMore: "Zobrazit více",
  ViewLess: "Zobrazit méně",
  SeeThisProductsJourney: "Podívejte se na cestu tohoto produktu",
  ProductJourney: "Cesta produktu",
  InteractiveMap: "Interaktivní mapa",
  ViewSupplyChain: "Zobrazit dodavatelský řetězec",
  BatchSpecificCertificates: "Certifikáty specifické pro šarži",
  CompleteChain: "Kompletní řetězec",
  BatchLookupFailedTryAgainLater: "Vyhledávání šarže selhalo, zkuste to později.",
}
