import { useEffect, useState } from "react"

import { ApolloError } from "@apollo/client"
import i18next from "i18next"
import { createContainer } from "unstated-next"

import {
  ExtendedItemDetailsFragment,
  SkuDetailsFragment,
  SupplyChainStoryStepFragment,
  TransactionDetailsFragment,
  PublicTransactionDetailsFragment,
  IngredientDetailsFragment,
} from "@/graphql/hooks/fragments.generated"
import {
  useItemExtendedDetailsQuery,
  useLotExtendedDetailsLazyQuery,
  useLotExtendedDetailsQuery,
  useSkuQuery,
} from "@/graphql/hooks/queries.generated"
import { selectBestPromotion } from "@/helpers/quickFunctions"

type LandingPageInitialData = {
  /* Preloaded Data */

  sku?: SkuDetailsFragment
  skuLoading?: boolean
  skuError?: ApolloError

  extendedDetails?: ExtendedItemDetailsFragment
  extendedDetailsLoading?: boolean
  extendedDetailsError?: ApolloError

  /* Auto-Load Initiators */

  skuID?: string
  skuGTIN?: string
  lotCode?: string
  itemUID?: string

  /* Flags & Props */

  /** When this is true use the `useCompleteChain` hook to complete a products chain */
  enableChainCompletion?: boolean

  /** Included when you scan a registration code (qr2) */
  rid?: string

  /** Included when you scan a batch code */
  batchCodePrefill?: string
}

/** Mappers to map supply chain story objects to transactions and history */
const stepToTransaction = (step: SupplyChainStoryStepFragment): TransactionDetailsFragment => ({
  ...step,
  __typename: "Transaction",
  locationName: step.address,
  // locationBounds: step.geoLocation,
  locationGeohash: step.geohash,
  createdByName: step.creatorName,
  createdAt: step.createdAt,
  action: { name: step.trackAction?.name ?? "", photos: step.trackAction?.photos },
})
export const transactionToPublicTransaction = (
  transaction: PublicTransactionDetailsFragment | TransactionDetailsFragment,
): PublicTransactionDetailsFragment => ({
  ...transaction,
  __typename: "PublicTransaction",
  action: { ...transaction.action, __typename: "PublicTrackAction" },
})

function useLandingPageDataHook(initialState?: LandingPageInitialData) {
  if (!initialState) {
    console.error("Landing Page Data Container cannot be initialized without a valid initial state")
  }

  // Primary Data

  const [sku, setSku] = useState(initialState?.sku)
  const [skuLoading, setSkuLoading] = useState(initialState?.skuLoading ?? false)
  const [skuError, setSkuError] = useState(initialState?.skuError)

  const [extendedDetails, setExtendedDetails] = useState(initialState?.extendedDetails)
  const [extendedDetailsLoading, setExtendedDetailsLoading] = useState(initialState?.extendedDetailsLoading ?? false)
  const [extendedDetailsError, setExtendedDetailsError] = useState(initialState?.extendedDetailsError)

  // Auto Loaders

  // Sku
  const {
    data: autoSkuData,
    loading: autoSkuLoading,
    error: autoSkuError,
  } = useSkuQuery({
    variables: { id: initialState?.skuID, gtin: initialState?.skuGTIN },
    skip: !!initialState?.sku || (!initialState?.skuID && !initialState?.skuGTIN),
  })
  useEffect(() => {
    if (autoSkuData) setSku(autoSkuData.sku)
  }, [autoSkuData])
  useEffect(() => {
    setSkuLoading(autoSkuLoading)
  }, [autoSkuLoading])
  useEffect(() => {
    setSkuError(autoSkuError)
  }, [autoSkuError])

  // Lot
  const skuID = initialState?.skuID ?? initialState?.sku?.id
  const {
    data: autoLotData,
    loading: autoLotLoading,
    error: autoLotError,
    called: autoLotCalled,
  } = useLotExtendedDetailsQuery({
    variables: {
      skuID,
      gtin: initialState?.skuGTIN,
      lotCode: initialState?.lotCode,
    },
    skip: !(initialState?.lotCode && (skuID || initialState?.skuGTIN)),
  })
  useEffect(() => {
    if (autoLotData) setExtendedDetails(autoLotData.lot.item)
  }, [autoLotData])
  useEffect(() => {
    setExtendedDetailsLoading(autoLotLoading)
  }, [autoLotLoading])
  useEffect(() => {
    setExtendedDetailsError(autoLotError)
  }, [autoLotError])

  // Product
  const {
    data: autoItemData,
    loading: autoItemLoading,
    error: autoItemError,
    called: autoItemCalled,
  } = useItemExtendedDetailsQuery({
    variables: { uid: initialState?.itemUID ?? "" },
    skip: !initialState?.itemUID,
  })
  useEffect(() => {
    if (autoItemData) setExtendedDetails(autoItemData.item)
  }, [autoItemData])
  useEffect(() => {
    setExtendedDetailsLoading(autoItemLoading)
  }, [autoItemLoading])
  useEffect(() => {
    setExtendedDetailsError(autoItemError)
  }, [autoItemError])

  useEffect(() => {
    if (sku?.language) {
      console.info("Changing language to " + sku?.language)
      i18next.changeLanguage(sku?.language)
    }
  }, [sku?.language])

  // Hooks for manual loading (eg: hook up to batch lookup input box)

  const [fetchLot, fetchLotStatus] = useLotExtendedDetailsLazyQuery()
  const lotLookup = async (variables: {
    gtin?: string
    skuID?: string
    lotCode?: string
  }): Promise<{ extendedDetails?: ExtendedItemDetailsFragment; extendedDetailsError?: any }> => {
    setExtendedDetailsLoading(true)
    try {
      const result = await fetchLot({ variables })
      const extendedDetails = result.data?.lot.item
      const extendedDetailsError = result.error
      setExtendedDetails(extendedDetails)
      setExtendedDetailsError(extendedDetailsError)
      return { extendedDetails, extendedDetailsError }
    } catch (err) {
      console.error(err)
      if (err instanceof ApolloError) setExtendedDetailsError(err)
      return { extendedDetailsError: err }
    } finally {
      setExtendedDetailsLoading(false)
    }
  }

  const lookupCalled = !!extendedDetails || autoLotCalled || autoItemCalled || fetchLotStatus.called
  const lookupLoaded = lookupCalled && !extendedDetailsLoading

  const supplyChainStory = extendedDetails?.supplyChainStory ?? sku?.item?.supplyChainStory
  const supplyChainStoryTransactions: TransactionDetailsFragment[] =
    supplyChainStory?.steps?.map(stepToTransaction) ?? []
  const supplyChainStoryIngredients: IngredientDetailsFragment[] =
    supplyChainStory?.rawMaterialStories?.map((story) => ({
      product: { sku: story.sku },
      transactions: { total: story.steps?.length ?? 0, transactions: story.steps?.map(stepToTransaction) ?? [] },
    })) ?? []

  return {
    /* Primary Data */

    /** If `sku` is `undefined` it means the page is loading (see `skuLoading`) or there was an error loading the sku (see `skuError`) */
    sku,
    /** Use this to control the initial */
    skuLoading,
    skuError,

    /** Use this to lookup lot data. Fetched data will be both returned and stored in this container */
    lotLookup,
    extendedDetails,
    extendedDetailsLoading,
    extendedDetailsError,

    /* Secondary Data (shortcuts to data in the above objects) */

    /** True as soon as any extendedDetails query has been called at least once. Once called you should either have `extendedDetailsLoading === true` or `extendedDetails !== undefined` or `extendedDetailsError !== undefined`. */
    lookupCalled,
    /** True after any extendedDetails query is **successfully** called. A lookup that doesn't succeed will set `lookupCalled` to `true` and `extendedDetailsError` to whatever error was returned instead of data. */
    lookupLoaded,

    /** This is the promotion that should pop up on the landing page */
    promotion: initialState?.sku ? selectBestPromotion(initialState?.sku) : undefined,

    skuCertificates: initialState?.sku?.certificates,
    extraCertificates: extendedDetails?.certificates,

    supplyChainStory,
    supplyChainStoryTransactions,
    supplyChainStoryIngredients,

    tracking: extendedDetails?.transactions,
    ingredients: extendedDetails?.ingredients.parents.list,

    /* Flags & Props */

    /** When this is true use the `useCompleteChain` hook to complete a products chain */
    enableChainCompletion: initialState?.enableChainCompletion,

    /** Included when you scan a registration code (qr2) */
    rid: initialState?.rid,

    /** Included when you scan a batch code */
    batchCodePrefill: initialState?.batchCodePrefill,
  }
}

export const LandingPageData = createContainer(useLandingPageDataHook)
export const useLandingPageData = LandingPageData.useContainer

export default LandingPageData
