// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Kan ikke finne batchnummer",
  GetBatchNumber: "Få batchnummer",
  InputBatchNumber: "Skriv inn batchnummer",
  ProductTracking: "Produkt Sporing",
  IngredientsTracking: "Ingrediens Sporing",
  IngredientsStory: "Ingrediens Historie",
  ProductDetails: "Produkt Detaljer",
  SeeMyJourney: "Se min reise",
  WhatPeopleSaidAboutUs: "Hva folk sa om oss",
  FeaturedIn: "Utgitt i",
  YouMightAlsoLike: "Du kan også like",
  ViewCertificate: "Vis sertifikat",
  MoreInformation: "Mer informasjon",
  Document: "Dokument",
  PoweredBy: "Drevet av",
  Explore: "Utforsk",
  FollowUs: "Følg oss",
  // New Landing page / Template 5
  Details: "Detaljer",
  Passport: "Pass",
  Certificates: "Sertifikater",
  Socials: "Sosiale",
  Home: "Hjem",
  AboutUs: "Om oss",
  TermsAndConditions: "Vilkår og betingelser",
  PrivacyPolicy: "Personvernregler",
  Reviews: "Anmeldelser",
  NutritionalInformation: "Nærings Informasjon",
  Videos: "Videoer",
  SocialMedia: "Sosiale medier",
  Attributes: "Attributter",
  MoreReviews: "Flere anmeldelser",
  AvailableAt: "Tilgjengelig på",
  Nutrition: "Ernæring",
  Competition: "Konkurranse",
  Loyalty: "Lojalitet",
  Promotions: "Kampanjer",
  ThankYouForYourSubmission:
    "Takk for innsendingen din. Vi vil sjekke blokken din og sende bekreftelsen på deltakelsen på e-post.",
  Submit: "Sende inn",
  UploadYourReceipt: "Last opp kvitteringen din",
  SupplyChainStory: "Leveringskjede historie",
  View: "Vis",
  ViewProducts: "Vis produkter",
  ViewMap: "Vis kart",
  ViewMore: "Vis mer",
  ViewLess: "Vis mindre",
  SeeThisProductsJourney: "Se denne produktets reise",
  ProductJourney: "Produkt reise",
  InteractiveMap: "Interaktivt kart",
  ViewSupplyChain: "Vis leveringskjeden",
  BatchSpecificCertificates: "Batch-spesifikke sertifikater",
  CompleteChain: "Fullfør kjede",
  BatchLookupFailedTryAgainLater: "Batchoppslag mislyktes, prøv igjen senere",
}
