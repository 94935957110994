// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Kan geen batchnummer vinden?",
  GetBatchNumber: "Batchnummer ophalen",
  InputBatchNumber: "Batchnummer invoeren",
  ProductTracking: "Product Tracking",
  IngredientsTracking: "Ingrediënten Tracking",
  IngredientsStory: "Ingrediënten Verhaal",
  ProductDetails: "Product Details",
  SeeMyJourney: "Bekijk mijn reis",
  WhatPeopleSaidAboutUs: "Wat mensen over ons zeiden",
  FeaturedIn: "Uitgelicht in",
  YouMightAlsoLike: "Je zou ook leuk kunnen vinden",
  ViewCertificate: "Certificaat bekijken",
  MoreInformation: "Meer informatie",
  Document: "Document",
  PoweredBy: "Aangedreven door",
  Explore: "Verkennen",
  FollowUs: "Volg ons",
  // New Landing page / Template 5
  Details: "Details",
  Passport: "Paspoort",
  Certificates: "Certificaten",
  Socials: "Sociale media",
  Home: "Thuis",
  AboutUs: "Over ons",
  TermsAndConditions: "Algemene voorwaarden",
  PrivacyPolicy: "Privacybeleid",
  Reviews: "Beoordelingen",
  NutritionalInformation: "Voedingsinformatie",
  Videos: "Video's",
  SocialMedia: "Sociale media",
  Attributes: "Kenmerken",
  MoreReviews: "Meer beoordelingen",
  AvailableAt: "Verkrijgbaar bij",
  Nutrition: "Voeding",
  Competition: "Competitie",
  Loyalty: "Loyaliteit",
  Promotions: "Promoties",
  ThankYouForYourSubmission:
    "Bedankt voor je inzending. Je ontvangstbewijs wordt gecontroleerd en de bevestiging van je deelname wordt per e-mail naar je verzonden.",
  Submit: "Verzenden",
  UploadYourReceipt: "Upload uw ontvangstbewijs",
  SupplyChainStory: "Supply Chain Verhaal",
  View: "Bekijken",
  ViewProducts: "Producten bekijken",
  ViewMap: "Kaart bekijken",
  ViewMore: "Meer bekijken",
  ViewLess: "Minder bekijken",
  SeeThisProductsJourney: "Bekijk de reis van dit product",
  ProductJourney: "Productreis",
  InteractiveMap: "Interactieve kaart",
  ViewSupplyChain: "Bekijk de supply chain",
  BatchSpecificCertificates: "Batch specifieke certificaten",
  CompleteChain: "Complete keten",
  BatchLookupFailedTryAgainLater: "Batch opzoeken mislukt, probeer het later opnieuw.",
}
