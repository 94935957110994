import React from "react"

import { KnownLandingPages } from "@/landingPages/Switcher"

const Demo = () => {
  const [landingPage, setLandingPage] = React.useState("Example")

  const demos: [string, string][] = [
    ["'Tropical Juice' used in wireframe examples", "/staging/s/217"],
    ["Sku page with batch lookup enabled", "/staging/s/125"],
    ["Sku page with batch lookup disabled", "/staging/s/197"],
    ["Batch QR code", "/staging/b/SKU00185-2024.01.09-12:38"],
    ["Product with chain completion turned on", "/staging/p/Wjlpqd"],
    ["Product with chain completion turned off", "/staging/p/AGn_ZR"],
    ["QR2 Scan (product already closed)", "/staging/r/sxvt5r"],
  ]
  return (
    <div>
      <h1>Demo a landing page</h1>
      Page to demo:{" "}
      <select value={landingPage} onChange={(e) => setLandingPage(e.target.value)}>
        {Object.values(KnownLandingPages).map((x) => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </select>
      <br />
      <ul>
        {demos.map((x) => (
          <li>
            <a href={x[1] + "?landingPage=" + landingPage}>{x[0]}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Demo
