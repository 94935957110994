// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "Δεν μπορείτε να βρείτε έναν αριθμό παρτίδας",
  GetBatchNumber: "Λήψη αριθμού παρτίδας",
  InputBatchNumber: "Εισαγάγετε τον αριθμό παρτίδας",
  ProductTracking: "Παρακολούθηση προϊόντος",
  IngredientsTracking: "Παρακολούθηση συστατικών",
  IngredientsStory: "Ιστορία συστατικών",
  ProductDetails: "Λεπτομέρειες προϊόντος",
  SeeMyJourney: "Δείτε το ταξίδι μου",
  WhatPeopleSaidAboutUs: "Τι είπαν οι άνθρωποι για εμάς",
  FeaturedIn: "Προβάλλεται σε",
  YouMightAlsoLike: "Μπορεί να σας αρέσει επίσης",
  ViewCertificate: "Προβολή πιστοποιητικού",
  MoreInformation: "Περισσότερες πληροφορίες",
  Document: "Έγγραφο",
  PoweredBy: "Υποστηρίζεται από",
  Explore: "Εξερευνήστε",
  FollowUs: "Ακολουθήστε μας",
  // New Landing page / Template 5
  Details: "Λεπτομέρειες",
  Passport: "Διαβατήριο",
  Certificates: "Πιστοποιητικά",
  Socials: "Κοινωνικά",
  Home: "Αρχική",
  AboutUs: "Σχετικά με εμάς",
  TermsAndConditions: "Όροι & Προϋποθέσεις",
  PrivacyPolicy: "Πολιτική απορρήτου",
  Reviews: "Κριτικές",
  NutritionalInformation: "Διατροφικές πληροφορίες",
  Videos: "Βίντεο",
  SocialMedia: "Κοινωνικά μέσα",
  Attributes: "Χαρακτηριστικά",
  MoreReviews: "Περισσότερες κριτικές",
  AvailableAt: "Διαθέσιμο σε",
  Nutrition: "Διατροφή",
  Competition: "Διαγωνισμός",
  Loyalty: "Πιστότητα",
  Promotions: "Προσφορές",
  ThankYouForYourSubmission:
    "Ευχαριστούμε για την υποβολή σας. Η απόδειξή σας θα επαληθευτεί και η επιβεβαίωση της συμμετοχής σας θα σας αποσταλεί με email.",
  Submit: "Υποβολή",
  UploadYourReceipt: "Ανεβάστε την απόδειξή σας",
  SupplyChainStory: "Ιστορία αλυσίδας εφοδιασμού",
  View: "Προβολή",
  ViewProducts: "Προβολή προϊόντων",
  ViewMap: "Προβολή χάρτη",
  ViewMore: "Προβολή περισσότερων",
  ViewLess: "Προβολή λιγότερων",
  SeeThisProductsJourney: "Δείτε το ταξίδι αυτού του προϊόντος",
  ProductJourney: "Ταξίδι προϊόντος",
  InteractiveMap: "Διαδραστικός χάρτης",
  ViewSupplyChain: "Προβολή αλυσίδας εφοδιασμού",
  BatchSpecificCertificates: "Πιστοποιητικά συγκεκριμένης παρτίδας",
  CompleteChain: "Πλήρης αλυσίδα",
  BatchLookupFailedTryAgainLater: "Η αναζήτηση παρτίδας απέτυχε, δοκιμάστε ξανά αργότερα.",
}
