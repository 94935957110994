import React from "react"

import LandingPageProps from "./LandingPageProps"
import Switcher from "./Switcher"

const UnpublishedView: React.FC<LandingPageProps> = (props) => {
  const [preview, setPreview] = React.useState(false)

  if (preview) return <Switcher {...props} show />

  return (
    <div>
      <h1>{props.sku.name} is an unpublished sku</h1>
      <button onClick={() => setPreview(true)}>Show preview</button>
    </div>
  )
}

export default UnpublishedView
