// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CantFindABatchNumber: "ไม่พบหมายเลขชุด",
  GetBatchNumber: "รับหมายเลขชุด",
  InputBatchNumber: "ป้อนหมายเลขชุด",
  ProductTracking: "การติดตามผลิตภัณฑ์",
  IngredientsTracking: "การติดตามส่วนผสม",
  IngredientsStory: "เรื่องราวของส่วนผสม",
  ProductDetails: "รายละเอียดผลิตภัณฑ์",
  SeeMyJourney: "ดูการเดินทางของฉัน",
  WhatPeopleSaidAboutUs: "คนพูดถึงเรา",
  FeaturedIn: "เด่นใน",
  YouMightAlsoLike: "คุณอาจชอบ",
  ViewCertificate: "ดูใบรับรอง",
  MoreInformation: "ข้อมูลเพิ่มเติม",
  Document: "เอกสาร",
  PoweredBy: "ขับเคลื่อนโดย",
  Explore: "สำรวจ",
  FollowUs: "ติดตามเรา",
  Details: "รายละเอียด",
  Passport: "พาสปอร์ต",
  Certificates: "ใบรับรอง",
  Socials: "โซเชียล",
  Home: "หน้าแรก",
  AboutUs: "เกี่ยวกับเรา",
  TermsAndConditions: "ข้อกำหนดและเงื่อนไข",
  PrivacyPolicy: "นโยบายความเป็นส่วนตัว",
  Reviews: "รีวิว",
  NutritionalInformation: "ข้อมูลโภชนาการ",
  Videos: "วิดีโอ",
  SocialMedia: "โซเชียลมีเดีย",
  Attributes: "คุณสมบัติ",
  MoreReviews: "รีวิวเพิ่มเติม",
  AvailableAt: "มีจำหน่ายที่",
  Nutrition: "โภชนาการ",
  Competition: "การแข่งขัน",
  Loyalty: "ความภักดี",
  Promotions: "โปรโมชั่น",
  ThankYouForYourSubmission: "ขอบคุณสำหรับการส่งข้อมูลของคุณ เราจะตรวจสอบบล็อกของคุณและส่งการยืนยันการเข้าร่วมทางอีเมล",
  Submit: "ส่ง",
  UploadYourReceipt: "อัปโหลดใบเสร็จของคุณ",
  SupplyChainStory: "เรื่องราวของโซ่อุปทาน",
  View: "ดู",
  ViewProducts: "ดูผลิตภัณฑ์",
  ViewMap: "ดูแผนที่",
  ViewMore: "ดูเพิ่มเติม",
  ViewLess: "ดูน้อยลง",
  SeeThisProductsJourney: "ดูการเดินทางของผลิตภัณฑ์นี้",
  ProductJourney: "การเดินทางของผลิตภัณฑ์",
  InteractiveMap: "แผนที่แบบโต้ตอบ",
  ViewSupplyChain: "ดูโซ่อุปทาน",
  BatchSpecificCertificates: "ใบรับรองเฉพาะชุด",
  CompleteChain: "โซ่เสริม",
  BatchLookupFailedTryAgainLater: "การค้นหาชุดล้มเหลว ลองอีกครั้งในภายหลัง",
}
